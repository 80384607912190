import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { styled, TypographyProps, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import clone from 'lodash/clone';
import { toDataURL } from 'qrcode';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AuthContainer from './AuthContainer';
import CodeForm from './form/CodeForm';
import LoginForm from './form/LoginForm';
import ResetPasswordForm from './form/ResetPasswordForm';
import {
  authViaApi,
  CognitoUserExt,
  CognitoUserType,
  configureAmplify,
  getMfaSetupCodeCognito,
  loginWithSocialProvider,
  mfaSignInToCognito,
  setNewPasswordCognito,
  signInToCognito,
  verifyMfaSetupCognito,
} from '../../lib/cognito';
import '../../lib/i18n';
import { AuthMethod, UserLogin } from '../../lib/types';
import { Caption, SubHead } from '../typography/legacy/Text';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2.5),
}));

const QrWrapper = styled(Caption)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}));

interface LoginProps {
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  redirectTrailingSlash?: boolean;
  onLoginSubmit?: () => void;
  onLogin?: (provider?: CognitoHostedUIIdentityProvider) => void;
  onSuccess: (user?: CognitoUserExt) => void | Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (err: any) => void;
  forgotPasswordHref: string;
  signUpHref?: string;
  showMobileAppOffer?: boolean;
  customHeader?: string;
  subtitle?: string;
  userType: CognitoUserType;
  sessionBased?: boolean;
  showSocialButtons?: boolean;
  TitleComponent?: FunctionComponent<TypographyProps>;
  SubtitleComponent?: FunctionComponent<TypographyProps>;
  aboveHeader?: React.ReactNode;
  autofillFields?: Partial<UserLogin>;
  primaryMethod?: AuthMethod;
}

const Login: React.FC<LoginProps> = ({
  userPoolId,
  userPoolWebClientId,
  authenticationFlowType,
  redirectTrailingSlash,
  onLoginSubmit,
  onLogin,
  onSuccess,
  onError,
  forgotPasswordHref,
  signUpHref,
  showMobileAppOffer,
  customHeader,
  subtitle,
  userType,
  sessionBased = true,
  showSocialButtons = false,
  TitleComponent,
  SubtitleComponent,
  aboveHeader,
  autofillFields,
  primaryMethod,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('login');
  const [cognitoUser, setCognitoUser] = useState<CognitoUserExt | null>(null);
  const [mfaSetupCode, setMfaSetupCode] = useState<string | null>(null);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    if (Boolean(userPoolId) && Boolean(userPoolWebClientId))
      configureAmplify({
        userPoolId,
        userPoolWebClientId,
        authenticationFlowType,
        redirectTrailingSlash,
      });
  }, [userPoolId, userPoolWebClientId]);

  const onNoChallengesLeft = async () => {
    if (sessionBased) {
      await authViaApi(userType);
    }
    await onSuccess(cognitoUser as CognitoUserExt);
  };

  useEffect(() => {
    if (cognitoUser && !cognitoUser.challengeName) {
      void onNoChallengesLeft();
    }
  }, [cognitoUser]);

  const logIn = async (username: string, password: string) => {
    try {
      if (onLogin) onLogin();
      const user = await signInToCognito(username, password);
      setCognitoUser(user);
    } catch (e) {
      if (onError) onError(e);
      const err = e as { code: string; name: string; message: string };
      setToastMessage(err.message);
    }
  };

  const socialLogIn = async (provider: CognitoHostedUIIdentityProvider) => {
    try {
      if (onLogin) onLogin(provider);
      await loginWithSocialProvider(provider);
      void onSuccess();
    } catch (e) {
      if (onError) onError(e);
      const err = e as { code: string; name: string; message: string };
      setToastMessage(err.message);
    }
  };

  const mfaLogIn = async (mfaCode: string) => {
    try {
      if (cognitoUser) {
        await mfaSignInToCognito(cognitoUser, mfaCode);
        await onNoChallengesLeft();
      }
    } catch (e) {
      if (onError) onError(e);
      const err = e as { code: string; name: string; message: string };
      setToastMessage(err.message);
    }
  };

  const setNewPassword = async (newPassword: string) => {
    try {
      if (cognitoUser) {
        const user: CognitoUserExt = await setNewPasswordCognito(newPassword, cognitoUser);
        // Ensure that the reference is not the same so a re-render is triggered
        setCognitoUser(clone(user));
      }
    } catch (e) {
      if (onError) onError(e);
      const err = e as { code: string; name: string; message: string };
      setToastMessage(err.message);
    }
  };

  const getMfaSetupCode = async () => {
    const issuer = encodeURIComponent('Outcomes4Me Admin');
    try {
      if (cognitoUser) {
        const setupTextCode = await getMfaSetupCodeCognito(cognitoUser);
        const setupQrCode = await toDataURL(
          `otpauth://totp/${issuer}:${cognitoUser.getUsername()}?issuer=${issuer}&secret=${setupTextCode}`,
        );
        setMfaSetupCode(setupTextCode);
        setQrCode(setupQrCode);
      }
    } catch (e) {
      if (onError) onError(e);
      const err = e as { code: string; name: string; message: string };
      setToastMessage(err.message);
    }
  };

  const verifyMfaSetup = async (mfaCode: string) => {
    try {
      if (cognitoUser) {
        await verifyMfaSetupCognito(cognitoUser, mfaCode);
        void onNoChallengesLeft();
      }
    } catch (e) {
      if (onError) onError(e);
      const err = e as { code: string; name: string; message: string };
      setToastMessage(err.message);
    }
  };

  const renderContent = (): ReactElement | null => {
    if (!cognitoUser) {
      return (
        <AuthContainer
          title={customHeader || t('logInToYourAccount', 'Log in to your account')}
          subtitle={subtitle}
          showMobileAppOffer={showMobileAppOffer}
          aboveHeader={aboveHeader}
        >
          <LoginForm
            onSubmit={({ email, password }: UserLogin) => {
              if (onLoginSubmit) onLoginSubmit();
              void logIn(email, password);
            }}
            onSocialLogin={socialLogIn}
            forgotPasswordHref={forgotPasswordHref}
            signUpHref={signUpHref}
            showSocialButtons={showSocialButtons}
            autofillFields={autofillFields}
            primaryMethod={primaryMethod}
          />
        </AuthContainer>
      );
    }
    switch (cognitoUser.challengeName) {
      case 'SOFTWARE_TOKEN_MFA':
        return (
          <AuthContainer
            title={t('multiFactorAuth', 'Multi-factor Authentication')}
            subtitle={t(
              'multiFactorAuthInstructions',
              'Enter the code from your multi-factor authentication app',
            )}
            showMobileAppOffer={showMobileAppOffer}
            TitleComponent={TitleComponent}
            SubtitleComponent={SubtitleComponent}
            aboveHeader={aboveHeader}
          >
            <CodeForm
              onSubmit={(mfaCode) => {
                void mfaLogIn(mfaCode);
              }}
            />
            <StyledButton variant="text" onClick={() => setCognitoUser(null)}>
              {t('backToLogin', 'Back to login')}
            </StyledButton>
          </AuthContainer>
        );
      case 'NEW_PASSWORD_REQUIRED':
        return (
          <AuthContainer
            title={t('setNewPassword', 'Setup password for new account')}
            showMobileAppOffer={showMobileAppOffer}
            TitleComponent={TitleComponent}
            SubtitleComponent={SubtitleComponent}
            aboveHeader={aboveHeader}
          >
            <ResetPasswordForm
              onSubmit={(newPw) => {
                void setNewPassword(newPw);
              }}
            />
          </AuthContainer>
        );
      case 'MFA_SETUP':
        return (
          <AuthContainer
            title={t('setupMfa', 'Setup MFA for new account')}
            subtitle={t(
              'setupMfaInstructions',
              "Get a text or QR code to set up MFA in your app of choice, then enter the app's generated code to verify",
            )}
            showMobileAppOffer={showMobileAppOffer}
            TitleComponent={TitleComponent}
            SubtitleComponent={SubtitleComponent}
            aboveHeader={aboveHeader}
          >
            <StyledButton
              variant="outlined"
              onClick={() => {
                void getMfaSetupCode();
              }}
              disabled={mfaSetupCode !== null}
            >
              {t('getMfaSetupCode', 'Get MFA setup code')}
            </StyledButton>
            {mfaSetupCode && (
              <>
                <SubHead>{mfaSetupCode}</SubHead>
                {qrCode ? (
                  <img src={qrCode} alt="qr-code" style={{ marginTop: theme.spacing(2.5) }} />
                ) : (
                  <QrWrapper>
                    {t('qrCodePlaceholder', '(QR code could not be generated)')}
                  </QrWrapper>
                )}
                <CodeForm
                  onSubmit={(mfaString) => {
                    void verifyMfaSetup(mfaString);
                  }}
                />
              </>
            )}
          </AuthContainer>
        );
      default:
        if (cognitoUser.challengeName) {
          return <SubHead>{`Challenge ${cognitoUser.challengeName} is not supported.`}</SubHead>;
        }
        return null;
    }
  };

  return (
    <>
      <Snackbar
        open={toastMessage !== null}
        autoHideDuration={6000}
        onClose={() => setToastMessage(null)}
        message={toastMessage}
      />
      {renderContent()}
    </>
  );
};

export default Login;
